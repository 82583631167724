import {twMerge} from '@/stylesheets/twMerge';
import type {Mode} from '@/components/base/types';
import {buttonStyles} from '@/components/base/elements/Button/styles';

interface AccordionButtonProps {
  className?: string;
  children: React.ReactNode;
  componentName?: string;
  mode?: Mode;
  onClick?: () => void;
}

export default function AccordionButton({
  className = '',
  children,
  componentName,
  mode,
  onClick,
}: AccordionButtonProps) {
  const styles = buttonStyles({
    intent: 'secondary',
    mode,
    size: 'large',
    border: true,
  });
  return (
    <button
      className={twMerge(styles, className)}
      data-component-name={componentName}
      data-mode={mode}
      onClick={onClick}
      aria-controls="plans-features-comparison"
    >
      {children}
    </button>
  );
}
