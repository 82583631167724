import {cva} from 'class-variance-authority';
import cn from 'classnames';
import {useEffect, useRef, useState} from 'react';

import {useMediaQuery} from '@/hooks/useMediaQuery';
import {useTableResize} from '@/hooks/useTableResize';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import FeaturesTableV2 from '@/components/shared/Plans/FeaturesTable/FeaturesTableV2';
import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import AccordionButton from '@/pages/shopify.com/($locale)/pricing/components/AccordionButton/AccordionButton';
import {useScrollToTableRow} from '@/pages/shopify.com/($locale)/pricing/components/Comparison/hooks/useScrollToTableRow';
import {type normalizeServerTableData} from '@/components/shared/Plans/FeaturesTable/utils/normalizeServerTableData';

import {ButtonPlusIcon, ButtonMinusIcon} from './assets';

interface ComparisonProps {
  serverTableData?: ReturnType<typeof normalizeServerTableData>;
  showText?: string;
  hideText?: string;
  isSignupLink?: boolean;
  showTableByDefault?: boolean;
}

export default function Comparison({
  serverTableData,
  showText,
  hideText,
  isSignupLink = true,
  showTableByDefault = false,
}: ComparisonProps) {
  const {t} = useTranslations();
  const {t: tPlans} = useSharedTranslations('plans');
  const accordionRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showTable, setShowTable] = useState(showTableByDefault);
  const [tableSectionHeight, setTableSectionHeight] = useState(0);
  const [lastButtonClicked, setLastButtonClicked] = useState(-1);
  const isDesktopView = useMediaQuery('(min-width: 900px)');
  const prefersReducedMotion = useMediaQuery(
    '(prefers-reduced-motion: reduce)',
  );
  const buttonShowText = showText || t('pricing:toggleShowText');
  const buttonHideText = hideText || t('pricing:toggleHideText');

  const bgGradient = serverTableData
    ? 'bg-gradient-to-b from-[#F4FAFE] to-[#F9FEFF00]'
    : 'bg-[linear-gradient(180.72deg, #F4FAF8 -13.88%, #FFFFFF 99.34%]';

  const styles = cva(['overflow-hidden'], {
    variants: {
      prefersReducedMotion: {
        false: 'duration-500 transition-all',
      },
      isDesktopView: {
        true: 'md:block',
      },
      showTable: {
        true: 'mb-12',
        false: 'max-h-0',
      },
    },
  });

  useScrollToTableRow(setShowTable);

  useEffect(() => {
    if (!showTable && buttonRef.current && lastButtonClicked === 1) {
      buttonRef.current.scrollIntoView({block: 'center'});
    }
  }, [showTable, lastButtonClicked]);

  // if resize to mobile and come back table is not shown,
  // but background color is still present behind the content,
  // so hide table if resize to mobile
  useEffect(() => {
    if (!isDesktopView) {
      setShowTable(false);
    }

    if (isDesktopView && showTableByDefault) {
      setShowTable(true);
    }
  }, [isDesktopView, showTableByDefault]);

  useTableResize({ref: accordionRef, setHeight: setTableSectionHeight});
  return (
    <section className="relative transition-all duration-500 md:block">
      {/* to make this faster, we should use media queries instead */}
      {isDesktopView ? (
        <Section
          className={cn('py-0 gap-y-lg', {
            [bgGradient]: showTable && !serverTableData,
          })}
        >
          <div className="text-center md:block pt-5" ref={buttonRef}>
            <AccordionButton
              mode="light"
              componentName="show-pricing-table"
              onClick={() => {
                setLastButtonClicked(0);
                setShowTable(!showTable);
              }}
            >
              <span className="flex justify-center">
                {showTable ? (
                  <ButtonMinusIcon className="mr-2 inline-block mt-1.5" />
                ) : (
                  <ButtonPlusIcon className="mr-2 inline-block mt-1.5" />
                )}
                {showTable ? buttonHideText : buttonShowText}
              </span>
            </AccordionButton>
          </div>
          <div
            ref={accordionRef}
            className={cn(
              styles({prefersReducedMotion, isDesktopView, showTable}),
              bgGradient,
            )}
            style={{
              maxHeight: showTable ? `${tableSectionHeight ?? 0}px` : '0',
            }}
          >
            <SectionHeader
              headingHtml={tPlans('comparison.heading')}
              center={false}
              className="text-left py-16"
            />
            <Section className={cn('pt-0 pb-9', {[bgGradient]: showTable})}>
              <div className="container">
                {serverTableData && (
                  <FeaturesTableV2
                    table={serverTableData}
                    isSignupLink={isSignupLink}
                  />
                )}
              </div>
            </Section>
            <div className="container">
              <div className="border-t-shade-30 mx-auto hidden border-t border-solid pt-10 text-center md:block w-full">
                <AccordionButton
                  mode="light"
                  componentName="plans-show-tables"
                  onClick={() => {
                    setLastButtonClicked(1);
                    setShowTable(!showTable);
                  }}
                >
                  <span className="flex justify-center">
                    {showTable ? (
                      <ButtonMinusIcon className="mr-2 inline-block mt-1.5" />
                    ) : (
                      <ButtonPlusIcon className="mr-2 inline-block mt-1.5" />
                    )}
                    {showTable ? buttonHideText : buttonShowText}
                  </span>
                </AccordionButton>
              </div>
            </div>
          </div>
        </Section>
      ) : null}
    </section>
  );
}
